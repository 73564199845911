import React, { useState, useEffect, lazy } from "react";
import { Link } from "react-router-dom";
const Manhattan = lazy(() => import("./Manhattan"));
const WinProbability = lazy(() => import("./WinProbability"));
const Worm = lazy(() => import("./Worm"));


export const Charts = ({teams, probability, scoregrid, maxovers, title=true, tp='20px', complete }) => {
  useEffect (() => {
    
  });
  return (
    <>
    {scoregrid.overs ? (

        <div className='mt-[20px]'>
            {complete!=='true' ? (
                <div className='pb-[20px]'>
                    <WinProbability teams={teams} probability={probability} maxovers={maxovers} />
                </div>
            ) : ( null )}

            <div className='pb-[20px]'>
                <Worm teams={teams} scoregrid={scoregrid} maxovers={maxovers} />
            </div>

            <div className='pb-[20px]'>
                <Manhattan teams={teams} scoregrid={scoregrid} maxovers={maxovers}  />
            </div>

        </div>
    ) : ( null )}
    </>
  );
};
export default Charts;
